/*

Author: Rubén Pallarés
version: 2.0
*/

* {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

body {
    margin: 0;
    min-height: 100vh;
    max-width: 100vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section {
    clear: both;
}

.article-content {
    color: #ffffff;
    padding-bottom: 2em;
    max-height: fit-content;
    padding: 5%;
}

h1,
h2,
h3,
h4 {
    text-align: center;
}

a {
    text-decoration: none;
    color: inherit;
}

.article-title {
    padding: 1em 0 1em 0;
    font-size: 3em;
}

ul {
    list-style: none;
    margin-bottom: 0;
}

li>.titulo {
    font-style: italic;
}

.libros-slider>ul>li {
    float: left;
}

.portada>a {
    width: 200px;
    margin: auto;
}

.portada>a>img {
    margin: 10px;
}

.item-gallery {
    float: left;
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    width: 20%;
}

.item-gallery:hover {
    border: 1px solid #777;
}

.item-gallery img {
    width: 100%;
    height: auto;
}

footer {
    height: fit-content;
    text-align: center;
}

@media (max-width: 600px) {
    .biografia {
        /*background: url('/images/carmela.jpg');*/
        background-size: cover;
        background-position: center;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
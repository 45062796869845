footer {
    background-color: gray;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #ffffff;
}

.footer-content {
    display: inline-block;
    width: 100%;
    /*width: fit-content;*/
}

.footer-content>.content {
    float: right;
}

.content {
    width: 50%;
}

.content>h3,
.content>p {
    padding: 2px;
}

.content>.social-networks {
    display: inline-flex;
}

.social-networks>div {
    margin: 2px;
}

.sub-footer {
    clear: both;
    background-color: darkgray;
    bottom: 0;
}

@media only screen and (min-width: 600px) {}

@media only screen and (max-width: 775px) {
    .footer-content>.content {
        display: block;
        width: 100%;
    }
}
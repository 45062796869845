.books-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 15px;
    /*padding: 10px;*/
    align-content: center;
    justify-content: space-around;
    width: 100%;
}


/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 800px) {
    .books-grid {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 15px;
        /*padding: 10px;*/
        align-content: center;
        justify-content: space-around;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .books-grid {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 15px;
        /*padding: 10px;*/
        align-content: center;
        justify-content: space-around;
        width: 100%;
    }
}
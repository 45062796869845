section.home {
    max-width: 100%;
}

.image-container {
    position: relative;
    max-height: max-content;
    /*max-width: 100vw;*/
}

.imagen-portada {
    width: 100%;
}

.page-title {
    background-color: black;
    opacity: 0.8;
    font-size: 5em;
    padding: auto;
    position: absolute;
    top: 20%;
    color: white;
    padding: 5px 5px 5px 5px;
    width: 60%;
}

.article-content {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.article-content>.text-content {
    color: black;
    font-size: 1.5em;
    padding-right: 5px;
    line-height: 2em;
}

.text-content>img {
    padding-left: 5px;
    float: right;
    width: 30%;
}

@media only screen and (max-width: 775px) {
    .page-title {
        background-color: black;
        opacity: 0.8;
        font-size: 2em;
        padding: auto;
        position: absolute;
        top: 20%;
        color: white;
        padding: 5px 5px 5px 5px;
    }
}
.contacto {
    text-align: center;
    color: black;
}

.contacto>article>.article-title {
    background-color: white;
}

.article-content>p {
    margin: 1.5em;
    color: black;
}

.contact-form {
    margin: auto;
    width: 50%;
}

.label-name,
.label-email,
.label-subject
/*,
.label-message*/

{
    height: 2em;
}

.contact-form>div>input[type=text],
.contact-form>div>input[type=email],
.contact-form>div>textarea {
    width: 100%;
}

.contact-form>div>input[type=submit] {
    float: right;
}

@media only screen and (max-width:775px) {
    .contact-form {
        width: 100%;
    }
}
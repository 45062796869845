header {
    padding: 2em 2em 2em 2em;
    max-height: 5em;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.mobile-btn {
    display: none;
    float: right;
    width: 24px;
    height: 24px;
    justify-content: center;
}

.closebtn {
    display: none;
}

.menu-btn {
    width: 100%;
}

.menuBtnIcon {
    width: 70px;
}

.nav-item {
    display: block;
    color: black;
    text-align: center;
    padding: 16px;
    float: left;
}

.nav-item>a,
.nav-item>div {
    text-decoration: none;
    color: inherit;
    font-size: 1.5em;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover>.dropdown-menu {
    display: block;
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    header {
        height: 30px;
    }
    .social-media {
        margin: auto;
        width: max-content;
    }
    .nav-menu {
        width: 100%;
    }
    .desktop-menu {
        display: none;
    }
    .mobile-btn {
        display: block;
        position: fixed;
        width: 48px;
        height: 24px;
        background-color: #ffffff;
        border: none;
        z-index: 99;
    }
    .menu {
        display: none;
    }
    .menu-header {
        width: 100%;
    }
    .open-menu {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        background-color: #ffffff;
        overflow-x: hidden;
        transition: 1.5s;
        padding-top: 60px;
    }
    .nav-menu {
        display: grid;
        text-align: left;
    }
    .nav-item {
        color: black;
        text-align: center;
        padding: 10px;
        float: left;
    }
    .closebtn {
        display: block;
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        border: unset;
        background-color: white;
        margin-left: 50px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    header {
        height: 60px;
    }
    .social-media {
        margin: auto;
        width: max-content;
    }
    .nav-menu {
        width: 100%;
    }
    .desktop-menu {
        display: none;
    }
    .mobile-btn {
        display: block;
        position: fixed;
        width: 48px;
        height: 24px;
        background-color: #ffffff;
        border: none;
        z-index: 99;
    }
    .menu {
        display: none;
    }
    .menu-header {
        width: 100%;
    }
    .open-menu {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        background-color: #ffffff;
        overflow-x: hidden;
        transition: width 1.5s;
        padding-top: 60px;
    }
    .nav-menu {
        display: grid;
        text-align: left;
    }
    .nav-item {
        color: black;
        text-align: center;
        padding: 10px;
        float: left;
    }
    .closebtn {
        display: block;
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        border: unset;
        background-color: white;
        margin-left: 50px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    header {
        max-height: 10em;
    }
    .menu {
        display: block;
    }
    .mobile-btn {
        display: none;
    }
    .social-media {
        margin: auto;
        width: 30%;
        display: inline-block;
    }
    .nav-menu {
        width: 70%;
        display: inline-block;
    }
    .closebtn {
        display: none;
    }
    .desktop-menu {
        display: none;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}
.item-container {
    position: relative;
    border: solid;
    margin: auto auto 0 auto;
    width: 100%;
}

.item {
    width: 100%;
    margin-bottom: auto;
}

.image {
    height: 100%;
}

.details {
    padding: 0.5em 0 0.5em 0;
    margin: auto;
    width: 100%;
    text-align: center;
}

.details>.btn:hover {
    background-color: cornflowerblue;
    opacity: 0.5;
}

.modal-book {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}


/* Modal Content/Box */

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */
}

.details>.btn {
    margin-top: 5px;
    padding: 0.5em;
    background-color: cornflowerblue;
    width: 50%;
    font-weight: bold;
}

.image>img {
    width: 100%;
}
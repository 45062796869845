.App {
    min-height: 100vh;
    position: relative;
}

main {
    padding-top: 5em;
    padding-bottom: 134px;
    max-height: auto;
    display: block;
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    main {
        padding-top: 94px;
        padding-bottom: 163px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    main {
        padding-top: 124px;
        padding-bottom: 88px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    main {
        padding-bottom: 88px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}
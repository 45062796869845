.premio {
    color: black;
    border: solid 1px black;
    width: 100%;
    margin: auto;
}

.center-text {
    margin: 10%;
    /*width: 50%;*/
    height: 100%;
}

.premio>.premio-content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.premio>.premio-content>.row-container {
    /*width: 50%;*/
}

.premio>.premio-content>div.descripcion {
    text-align: center;
    width: 50%;
}

.image-premio-container {
    max-width: min-content;
}

.thumb {
    display: inline-block;
    /*width: 200px;*/
    width: min-content;
    height: 200px;
    margin: 5px;
}

@media only screen and (max-width: 775px) {
    .premio {
        color: black;
        border: solid 1px black;
        width: 100%;
        margin: auto;
    }
    .center-text {
        margin-top: 5%;
    }
    .thumb {
        height: 150px;
    }
}